.home {
  background: #34495e fixed;
  height: 120vh;
  background-size: cover;
}
@media (max-width: 1091px) {
  .home {
    height: 130vh;
  }
}
@media (max-width: 584px) {
  .home {
    height: 210vh;
  }
}
