@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  display: grid;
  grid-template-rows: 56px auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "Poppins", sans-serif;
}

nav ul {
  font-weight: 500;
}
.login-btn {
  position: absolute;
  top: 18px;
}
@media (max-width: 1038px) {
  .login-btn {
    margin-top: 150px !important;
  }
}

.all-vehicle-cards {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vehicle-card {
  width: 220px;
  height: 190px;
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}
.vehicle-card:hover {
  transform: scale(1.2, 1.2);
  border: 5px solid #2f3640;
}
.vehicle-card h2 {
  text-transform: uppercase;
  font-weight: bold;
}
.vehicle-img {
  width: 100px;
  margin: 15px;
}

.home {
  background: #34495e fixed;
  height: 120vh;
  background-size: cover;
}
@media (max-width: 1091px) {
  .home {
    height: 130vh;
  }
}
@media (max-width: 584px) {
  .home {
    height: 210vh;
  }
}

.parent {
  margin-left: 40px;
}
.search-results {
  width: 350px;
  height: 87px;
  background-color: #ecf0f1;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 7px;
  border-radius: 5px;
  margin: 13px;
  padding: 20px;
  display: flex;
}
.price {
  margin-left: 50px;
}
.vehicle-icon {
  width: 50px;
  height: 50px;
}
.people-icon {
  width: 40px;
  margin-left: 20px;
  margin-right: 10px;
}
.red-destination-div {
  background-color: #e74c3c;
  color: white;
  width: 350px;
  height: 120px;
  border-radius: 10px;
  margin-left: 15px;
  padding: 20px;
}

.container {
  display: flex;
  justify-content: center;
}
.signup {
  margin-top: 5.5rem;
  border: 2px solid black;
  width: 500px;
  height: 710px;
}
.google-sign-in,
.github-sign-in {
  width: 280px;
  margin: auto;
  border: 2px solid black;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.google-sign-in h5,
.github-sign-in h5 {
  text-align: center;
}
.google-sign-in img,
.github-sign-in img {
  margin-right: 25px;
}
.github-sign-in {
  margin-top: 20px;
}
.social-signin {
  margin-top: 2rem;
}
form {
  margin: 25px;
}
input:focus,
input:active {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.308) !important;
}
.social {
  width: 30px;
}
@media (max-width: 540px) {
  .signup {
    width: 400px;
  }
}

.not-found {
  height: 100vh;
  background-color: #222f3e;
  color: white;
}
.four-o-four {
  font-size: 6rem;
  font-weight: 300;
}
.not-found-text {
  font-size: 1.35rem;
  font-weight: 300;
}

