.parent {
  margin-left: 40px;
}
.search-results {
  width: 350px;
  height: 87px;
  background-color: #ecf0f1;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 7px;
  border-radius: 5px;
  margin: 13px;
  padding: 20px;
  display: flex;
}
.price {
  margin-left: 50px;
}
.vehicle-icon {
  width: 50px;
  height: 50px;
}
.people-icon {
  width: 40px;
  margin-left: 20px;
  margin-right: 10px;
}
.red-destination-div {
  background-color: #e74c3c;
  color: white;
  width: 350px;
  height: 120px;
  border-radius: 10px;
  margin-left: 15px;
  padding: 20px;
}
