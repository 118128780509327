nav ul {
  font-weight: 500;
}
.login-btn {
  position: absolute;
  top: 18px;
}
@media (max-width: 1038px) {
  .login-btn {
    margin-top: 150px !important;
  }
}
